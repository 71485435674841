import React, { useMemo } from "react"

import ReactECharts from "echarts-for-react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography, useTheme, chartsTheme, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { formatNumberWithUnit } from "../../utils/formatting"
import * as Styled from "./RoadworksBudgetsKpi.styled"

export function RoadworksBudgetsKpiComponent(props) {

    const {
        currency,
        data,
    } = props

    const theme = useTheme()
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)

    const chartOptions = useMemo(() => {
        const dates = data.map(d => d.date)
        const done = data.map(d => d.done)
        const planned = data.map(d => d.planned)

        return {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
                padding: 10,
                renderMode: "richText",
                valueFormatter: (value) => `${formatNumberWithUnit(value)}${currency}`,
            },
            xAxis: [
                {
                    type: "category",
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: dates,
                },
            ],
            yAxis: [
                {
                    type: "value",
                    position: "left",
                    alignTicks: true,
                    axisLine: {
                        show: true,
                    },
                    axisLabel: {
                        formatter: (value) => `${formatNumberWithUnit(value)}${currency}`,
                        fontWeight: "bold",
                    },
                },
            ],
            series: [
                {
                    emphasis: {
                        "disabled": true,
                    },
                    name: t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.done"),
                    type: "bar",
                    data: done,
                },
                {
                    emphasis: {
                        "disabled": true,
                    },
                    name: t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.planned"),
                    type: "bar",
                    data: planned,
                },
            ],
        }
    }, [data, currency, t])

    return (
        <Styled.Wrapper>
            <ReactECharts
                option={chartOptions}
                opts={{ renderer: "svg" }}
                theme={chartsTheme}
            />
            <Styled.LegendsWrapper isMobile={isMobile}>
                <Styled.LegendWrapper>
                    <Styled.Color color={theme.palette["cta-bg/cta-bg-primary"].main} />
                    <Typography>
                        {t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.done")}
                    </Typography>
                </Styled.LegendWrapper>
                <Styled.LegendWrapper>
                    <Styled.Color color={theme.palette["surfaces/surface-clair"].main} />
                    <Typography>
                        {t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.planned")}
                    </Typography>
                </Styled.LegendWrapper>
            </Styled.LegendsWrapper>
        </Styled.Wrapper >
    )
}


RoadworksBudgetsKpiComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.number.isRequired,
        done: PropTypes.oneOfType([PropTypes.number]),
        planned: PropTypes.oneOfType([PropTypes.number]),
    })).isRequired,
}