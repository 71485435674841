import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { useL2rMutation } from "@l2r-front/l2r-query"

export function useSvraiMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `svrai/${url}`
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()

    return useL2rMutation(
        apiUrl,
        axiosFn,
        { ...queryParameters, project: selectedNetwork?.slug },
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}