import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { useAuthenticatedQuery } from "@l2r-front/l2r-query"

export function useSvraiQuery(
    url,
    queryKey,
    queryParameters,
    config = {},
    initialData,
) {
    const apiUrl = `svrai/${url}`
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    queryKey.push({ network: selectedNetwork?.slug })

    return useAuthenticatedQuery(
        apiUrl,
        queryKey,
        { ...queryParameters, project: selectedNetwork?.slug },
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
        initialData,
    )
}